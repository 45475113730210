<template>
	<announcement-data
		:form-import="form"
		:errors-import="errors"
		:create-new-announcement="false"
		:priorities="priorities"
		:themes="themes"
		:announcement-import="announcementInformation"
		:loading="loading"
		@requestData="requestUpdateAnnouncement" />
</template>
<script>
import { PRIORITIES, THEMES, DEFAULTS } from '@/settings/Announcements';
import { Announcements as messages } from '@/translations';
import Announcement from '@/util/Announcement';
import AnnouncementData from './components/AnnouncementData';

export default {
	name: 'UpdateAnnouncement',
	messages,
	components: {
		AnnouncementData,
	},
	data() {
		return {
			announcement: new Announcement(),
			priorities: PRIORITIES,
			themes: THEMES,
			alert: new this.$Alert(),
			form: {
				// title: '',
				content: '',
				// regards: DEFAULTS.regards,
				// signature: DEFAULTS.signature,
				theme: DEFAULTS.theme,
				priority: DEFAULTS.priority,
				start_date: '',
				end_date: '',
				password: '',
			},
		};
	},
	computed: {
		errors: {
			get() {
				try {
					return this.announcement.data.errors.errors;
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.announcement.data.errors.errors = newErrors;
			},
		},
		loading() {
			try {
				return this.announcement.data.loading;
			} catch (error) {
				return false;
			}
		},
		response() {
			try {
				const { data } = this.announcement.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		data: {
			get() {
				try {
					return this.response.data.attributes;
				} catch (error) {
					return {};
				}
			},
			set(payload) {
				this.response.data.attributes = { ...this.response.data.attributes, ...payload };
			},
		},
		announcementInformation() {
			return this.data;
		},
		routeId() {
			return this.$route.params.announcementId;
		},
	},
	watch: {
		routeId: () => {
			this.announcement.loadAnnouncement(this.routeId);
		},
	},
	created() {
		this.announcement.loadAnnouncement(this.routeId);
		// this.form.regards = this.translate(this.form.regards);
		// this.form.signature = this.translate(this.form.signature, { app: process.env.VUE_APP_NAME });
	},
	methods: {
		requestUpdateAnnouncement(payload) {
			this.announcement.updateAnnouncement(this.routeId, payload).then(() => {
				try {
					this.alert.toast('success', this.translate('announcement_updated'));
				} finally {
					this.$router.push({ name: 'Announcements' });
				}
			}).catch(() => {
				this.data = payload;
			});
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
	},
};
</script>
